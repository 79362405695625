@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mea+Culpa&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Berkshire-Swash";
  src: url("../public/fonts/BerkshireSwash-Regular.ttf") format("truetype");
}

.w-inherit {
  width: inherit;
}
.w-full {
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
.App {
  font-family: "Berkshire Swash", serif;
}
.font-roboto {
  font-family: "Roboto", sans-serif;
}
.font-Culpa {
  font-family: "Mea Culpa", cursive;
}

.scrollbar-curved::-webkit-scrollbar {
  width: 12px;
}
.scrollbar-curved::-webkit-scrollbar-thumb {
  width: 12px;
  border-radius: 60px;
  background-color: gray;
  height: 5px;
  margin-right: 10px;
}

.title-font {
  font-family: "Berkshire-Swash";
}
/* .campain-img-bg{
    background-image: url('./assets/image/green.jpg');
} */
.campain-img-bg {
  background-color: rgb(227, 251, 251);
}
.campaign-image1-bg {
  background-image: url("./assets/CAMPAIGN/1x/Asset 2.png");
  width: 100%;
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.campaign-image2-bg {
  background-image: url("./assets/CAMPAIGN/1x/Asset 3.png");
  width: 100%;
  height: 55%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
  animation: Campaign-slideIn 2s ease-in;
}
.campaign-image3-bg {
  background-image: url("./assets/CAMPAIGN/1x/Asset 4.png");
  width: 100%;
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  margin-left: 5%;
  animation: Campaign2-slideIn 4s ease-in;
}

.campaign-image4-bg {
  background-image: url("./assets/CAMPAIGN/1x/Asset 5.png");
  width: 100%;
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
}

@keyframes Campaign-slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes Campaign2-slideIn {
  0% {
    transform: translateX(-250%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.Campaign-animate-slide-in {
}
.bg-inherit {
  background: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  height: 10px;
  border-radius: 5px;
  margin-right: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hidden-scrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.hidden-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.hidden-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.glass {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}
/* @layer components {
  .socialIcon {
    @apply w-11 border-[1px] border-text-darkgren border-solid h-11 rounded-full px-2 py-1 text-white  hover:text-darkgren cursor-pointer transition duration-200 hover:animate-pulse;
  }
} */

  /* .socialIcon {
    @apply w-11 border-[1px] border-text-darkgren border-solid h-11 rounded-full px-2 py-1 text-white  hover:text-darkgren cursor-pointer transition duration-200 hover:animate-pulse;
  } */
